<template>
  <v-card class="mx-auto">
    <v-card-title>
      {{ $t('module.survey.start.title') }}
    </v-card-title>
    <v-card-text>
      {{ $t('module.survey.start.content') }}
    </v-card-text>
    <v-card-actions class="pt-8">
      <v-card width="100%">
        <v-toolbar class="pa-1" flat>
          <v-select
            :items="$t('module.survey.statusgroups.items')"
            :hint="$t('module.survey.statusgroups.label')"
            persistent-hint
            v-model="user.statusGroup"
          ></v-select>
          <slot></slot>
        </v-toolbar>
        <v-card v-if="!user.statusGroup">
          <v-card-text>
            <h2>{{ $t('module.survey.statusgroups.select') }}</h2>
          </v-card-text>
        </v-card>
        <v-card
          flat
        >
          <v-card-text>
            <p>
              {{ statusGroupObject.desc }}
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              @click="nextStep"
              class="mx-auto"
              color="primary"
            >
              {{ $t('module.survey.statusgroups.continue') }} {{ statusGroupObject.text }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-card-actions>
  </v-card>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: "Intro",
    props: {
      nextStep: Function
    },
    computed: {
      ...mapState('dfg', {
        user: 'activeSurvey'
      }),
      statusGroupObject() {
        return this.$t('module.survey.statusgroups.items')
          .find(item => item.value === this.user.statusGroup);
      }
    }
  }
</script>

<style scoped>

</style>
