<template>
  <v-card>
    <v-card-title class="justify-center">{{ $t('module.survey.step1.title') }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="4">
          <v-text-field
            :label="$t('module.survey.step1.forename')"
            v-model="user.firstname"
            outlined
          >Vorname
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            :label="$t('module.survey.step1.surname')"
            v-model="user.lastname"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            :label="$t('module.survey.step1.birthyear')"
            v-model="user.birthyear"
            :rules="[rules.birtyear]"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col class="justify-center d-flex" cols="12" sm="4">
          <span style="padding: 14px; font-size: 17px">Gender:</span>
          <v-btn-toggle v-model="user.gender">
            <v-btn value="men" style="height: 56px">
              Male
            </v-btn>
            <v-btn value="women" style="height: 56px">
              Female
            </v-btn>
          </v-btn-toggle>
        </v-col>

        <v-col cols="12" sm="8">
          <v-autocomplete
            :items="$t('common.nationalities')"
            item-text="label"
            item-value="value"
            :hint="$t('module.survey.step1.nationality.label')"
            persistent-hint
            outlined
            v-model="user.nationality"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" no-gutters><p>If you hold multiple citizenship: Please state further nationalities.</p></v-col>
        <v-col cols="6">
          <v-autocomplete
              :items="$t('common.nationalities')"
              item-text="label"
              item-value="value"
              hint="2nd nationality"
              persistent-hint
              outlined
              v-model="user.nationality2"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <v-autocomplete
              :items="$t('common.nationalities')"
              item-text="label"
              item-value="value"
              hint="3rd nationality"
              persistent-hint
              outlined
              v-model="user.nationality3"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12">
          <v-divider/>
          <p class="pt-2 font-weight-bold">{{ $t('module.survey.step1.study.title') }}</p>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="availableSubjectGroups"
            item-text="label"
            item-value="value"
            :label="$t('module.survey.step1.study.area.label')"
            v-model="user.subjectGroup"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="availableSubjectAreas"
            item-text="label"
            item-value="value"
            :label="$t('module.survey.step1.study.field.label')"
            v-model="user.subjectArea"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="12">
          <v-divider/>
          <p class="pt-2 font-weight-bold">{{ $t('module.survey.step1.countryBeforeEnter.title') }}</p>
          <v-autocomplete
            :items="$t('common.countries')"
            item-text="label"
            item-value="value"
            :label="$t('module.survey.step1.countryBeforeEnter.label')"
            prepend-icon="mdi-city"
            v-model="user.countryBeforeEnter"
            outlined
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn :disabled="!readyForNextPage" @click="nextStep" color="primary">{{ $t('common.next') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "StepOne",
      props: {
        nextStep: {
          type: Function,
          required: true
        }
      },
      data: () => {
         return {
           rules: {
             birtyear: (value) => value <= 2020 || 'Please enter a valid birtyear',
           }
         }
      },
      computed: {
        ...mapState('dfg', {
          user: 'activeSurvey'
        }),
        readyForNextPage() {
          return this.user.firstname
          && this.user.lastname
          && this.user.birthyear
          && this.user.gender
          && this.user.nationality
          && this.user.subjectGroup
          && this.user.subjectArea
          && this.user.countryBeforeEnter;
        },
        availableSubjectGroups() {
          return this.$t('module.survey.step1.study.area.items');
        },
        availableSubjectAreas() {
          const entries = this.availableSubjectGroups.filter(value => value.value === this.user.subjectGroup);
          if (entries.length > 0) {
            return entries[0].fields;
          }

          return [];
        }
      }
    }
</script>

<style scoped>

</style>
