<template>
  <v-container>
    <div v-if="!finished && !isLoading && !success && !isSubmitted">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">Intro</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step editable :complete="step > 2" step="2">Personal data</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step editable :complete="step > 3" step="3">Financing</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step editable :complete="step > 4" step="4">Finale</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <intro :next-step="nextStep"/>
          </v-stepper-content>

          <v-stepper-content step="2">
            <step-one :next-step="nextStep"/>
          </v-stepper-content>

          <v-stepper-content step="3">
            <step-two :next-step="nextStep"/>
          </v-stepper-content>

          <v-stepper-content step="4">
            <step-three :user="active" :finish="finish"/>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
    <div v-else-if="!isLoading && isSubmitted">
      <v-row align="center" justify="center">
        <v-col class="text-center">
          <h2>You already submitted the {{ new Date().getFullYear() }} DFG Survey</h2>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="!isLoading && success">
      <v-row align="center" justify="center">
        <v-col class="text-center">
          <h2>You successfully submitted the {{ new Date().getFullYear() }} DFG Survey!</h2>
          <h3>To navigate through the intranet just select a unit in the left navigation.</h3>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-skeleton-loader
        class="mx-auto"
        max-width="1200"
        max-height="1200"
        type="card"
      ></v-skeleton-loader>
    </div>
  </v-container>

</template>

<script>
import Intro from "../components/Intro";
import StepOne from "../components/StepOne";
import StepTwo from "../components/StepTwo";
import StepThree from "../components/StepThree";
import {mapGetters, mapState} from "vuex";
import {fetchApi} from "../../../utils/fetch";
import {ENTRYPOINT_API} from "../../../config/entrypoint";

export default {
  name: "Dfg",
  components: {StepThree, StepTwo, StepOne, Intro},
  data: () => {
    return {
      step: 1,
      resp: {},
      finished: false,
      success: false,
      error: false,
      sendLoading: false,
    }
  },
  mounted() {

  },
  computed: {
    ...mapState('dfg', {
      active: 'activeSurvey'
    }),
    ...mapGetters('dfg', {
      mySurveys: 'mySurveys',
      isSubmitted: 'isSubmittedForCurrentYear'
    }),
    ...mapGetters('auth', {
      currentUser: 'user'
    }),
    isLoading() {
      return typeof this.active === 'undefined';
    }
  },
  methods: {
    nextStep() {
      this.step++;
    },
    finish() {
      this.sendLoading = true;

      this.active.user = this.currentUser.iri;

      this.$store.dispatch('dfg/create', this.active).then(resp => {
        this.sendLoading = false;
        this.success = true;
      }).catch(reason => {
        this.sendLoading = false;
        this.error = true;
      });
    }
  }
}
</script>


<style scoped>

</style>
