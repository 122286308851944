<template>
  <v-card>
    <v-card-title class="justify-center">{{ $t('module.survey.step2.title') }}</v-card-title>
    <v-card-text>
      <div>
        <h3>Please indicate for the period since the last survey (EXC: 09/2023), for what period of time you participated in the group with what type of funding and to which status group(s) you belonged during this time.</h3>
        <div style="padding-top: 6px;">If, during this time,</div>
        <ul>
          <li>you participated in the group with more than one type of funding, please complete a separate line for each funding type.</li>
          <li>you changed status group (e.g. from doctoral researcher to postdoctoral researcher), please also add a new lineand indicate the funding type and period of time for this new status group.</li>
        </ul>
        <div>Notes on type of funding:</div>
        <ul>
          <li>If the individual's post is funded with DFG project funds, please indicate the proportion as a percentage. The percentage indicates the proportion relative to a full-time post or full fellowship.</li>
          <li>Please also indicate periods of participation without group funding. "Without group funding" means that the individual's post is/was involved in the group during the specified period, but is/was NOT funded with DFG project funds of the group.</li>
        </ul>
      </div>
      <v-row>


        <!-- Finanzierung -->
        <v-col cols="12" sm="12" v-bind:key="index"
               v-for="(finance, index) in user.finances">
          <v-row>
            <v-col cols="12" sm="3">
              <v-select
                :items="$t('module.survey.statusgroups.items')"
                :label="$t('module.survey.statusgroups.label')"
                v-model="finance.statusGroup"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                :items="$t('module.survey.step2.financing.type.items')"
                item-text="label"
                item-value="value"
                :label="$t('module.survey.step2.financing.type.label')"
                v-model="finance.type"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-menu
                :close-on-content-click="true"
                max-width="290px"
                min-width="290px"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :label="$t('module.survey.step2.financing.start')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-model="finance.startDate"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title
                  scrollable
                  type="month"
                  max="2024-09-01"
                  v-model="finance.startDate"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="3">
              <v-menu
                :close-on-content-click="true"
                max-width="290px"
                min-width="290px"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :label="$t('module.survey.step2.financing.end')"
                    :disabled="finance.notEnded"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-model="finance.endDate"
                    v-on="on"
                  ></v-text-field>
                  <v-checkbox
                    :value="finance.notEnded"
                    @click="toggleEnded(finance)"
                    style="margin-top: 0;"
                    label="Not ended yet"
                  ></v-checkbox>
                </template>
                <v-date-picker
                  no-title
                  scrollable
                  type="month"
                  v-model="finance.endDate"
                  min="2023-09-01"
                  max="2024-09-01"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12">
          <v-btn @click="addNewFinanceEntry" block color="primary" text>
            {{ $t('module.survey.step2.financing.new') }}
          </v-btn>
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" sm="12">
          <p>{{ $t('module.survey.step2.financing.city.title') }}</p>
          <v-text-field
            :label="$t('module.survey.step2.financing.city.label')"
            v-model="user.place"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12">
          <p>{{ $t('module.survey.step2.financing.institution.title') }}</p>
          <v-text-field
            :label="$t('module.survey.step2.financing.institution.label')"
            v-model="user.institution"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn :disabled="!readyForNextPage" @click="nextStep" color="primary">{{ $t('common.next') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: "StepTwo",
    props: {
      nextStep: {
        type: Function,
        required: true
      }
    },
    computed: {
      ...mapState('dfg', {
        user: 'activeSurvey'
      }),
      readyForNextPage() {
        return this.user.finances.length >= 1
          && this.user.finances[0].statusGroup
          && this.user.finances[0].type
          && this.user.finances[0].startDate
          && (this.user.finances[0].endDate || this.user.finances[0].notEnded)
          && this.user.place
          && this.user.institution
      }
    },
    data() {
      return {
        availableFinancingTypes: [
          'ohne Verbundfinanzierung',
          '1 bis 49% verbund-finanziert',
          '50% oder mehr verbund-finanziert',
        ]
      }
    },
    mounted() {
      this.addNewFinanceEntry();
    },
    methods: {
      toggleEnded(item) {
        item.notEnded = !item.notEnded
        if(item.notEnded) {
          item.endDate = 'not ended';
        } else {
          item.endDate = '';
        }
      },
      addNewFinanceEntry() {
        this.user.finances.push({
          statusGroup: '',
          type: '',
          startDate: '',
          endDate: '',
          notEnded: false
        });
      },
    }
  }
</script>

<style scoped>

</style>
